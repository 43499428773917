@import url('//fonts.googleapis.com/css2?family=Nanum+Brush+Script&family=Nanum+Gothic&family=Nanum+Myeongjo&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
body {
  margin: 0;
  font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: keep-all; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

// html
//// @media (min-width: 960px)
////   margin: 0 auto
////   max-width: 1200px
////   padding: 0 30px

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px; }

h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
  font-weight: normal;
  border-bottom: 0; }

/* Material UI Breakpoints : https://material-ui.com/customization/breakpoints/#default-breakpoints */
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */; }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }

.draftjs {
  line-height: 1.8;
  p:empty {
    min-height: 20px; }
  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px; }
  iframe {
    width: 100%; }
  code {
    font-family: monospace;
    overflow-wrap: break-word;
    background: rgb(241, 241, 241);
    border-radius: 3px;
    padding: 1px 3px; }
  img {
    max-width: 100%;
    height: auto; } }

.intro-body {
  line-height: 1.8;
  word-break: break-all;
  p:empty {
    min-height: 20px; }
  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px; }
  iframe {
    width: 100%; }
  code {
    font-family: monospace;
    overflow-wrap: break-word;
    background: rgb(241, 241, 241);
    border-radius: 3px;
    padding: 1px 3px; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr {
    font-weight: bold; }
  .sideMargin {
    padding-right: 200px;
    padding-left: 200px;
    @media screen and (max-width: $sm) {
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (max-width: $sm) {
    padding-left: 20px;
    padding-right: 20px;
    img {
      max-width: 100%;
      height: auto; } } }

.ais-SearchBox {
  margin: 1em 0;
  .ais-SearchBox-form {
    display: flex; } }
.ais-Hits {
  .ais-Hits-list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    .ais-Hits-item {
      width: calc(100% - 1rem); } } }
.ais-Pagination {
  margin-top: 2em;
  .ais-Pagination-list {
    overflow: hidden;
    flex-wrap: wrap; } }

.hidden-post + .hidden-post {
  border-top: 0; }
